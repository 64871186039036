<template>
  <div>
    <h6 class="text">Seleziona Cap</h6>
    <v-select
        label="multi_cap"
        :options="options"
        v-model="select"
        @search="onSearchCap"
        @input="sendEmit"
    >
      <template slot="selected-option" slot-scope="option">
        {{ option.comune }} - ({{ option.multi_cap }})
      </template>
      <template slot="option" slot-scope="option">
        {{ option.comune }} - ({{ option.multi_cap }})
      </template>
      <span slot="no-options">Scrivi un cap</span>
    </v-select>
    <br>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      select: [],
      options: [],
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.select = this.selected;
      }
    }
  },
  methods: {
    sendEmit(value) {
      this.$emit("getSingleCap", value);
    },
    onSearchCap(search, loading) {
      if (search.length) {
        loading(true);
        this.searchMultiCap(loading, search, this);
      }
    },
    searchMultiCap: _.debounce((loading, search, vm) => {
      fetch(
          process.env.VUE_APP_BASEURL + `/settings/api/v0/lista-cap?multi_cap__icontains=${escape(search)}`)
          .then(res => {
            res.json().then(json => vm.options = json.results);
            loading(false);
          });
    }, 100),
  },
  props: {
    selected: {
      type: Object,
      default: []
    }
  },
  name: "SingleCap"
}
</script>
