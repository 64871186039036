<template>
  <div>
    <!-- https://github.com/xkjyeah/vue-google-maps -->

    <div>
      <h6 v-if="title === undefined" class="nome_cognome_bold">Indirizzo di residenza (*)</h6>
      <gmap-autocomplete
          @place_changed="setPlace"
          :value="address"
          @input="value = $event.target.value"
          placeholder="Inserisci indirizzo"
          class="form-control"
      >
      </gmap-autocomplete>
    </div>

    <br>
    <gmap-map
        :center="center"
        :zoom="20"
        style="width:100%;  height: 400px;"
    >
      <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center=m.position"
      ></gmap-marker>
    </gmap-map>

  </div>
</template>

<script>
export default {
  name: "GoogleMapsAutocomplete",
  data() {
    return {
      center: {},
      address: '',
      markers: [{position: {}}],
      places: [],
      currentPlace: null,
    };
  },
  watch: {
    addressProps: {
      immediate: true,
      handler() {
        this.address = this.addressProps;
      }
    },
    centerProps: {
      immediate: true,
      handler() {
        this.center = this.centerProps
      }
    },
    markersProps: {
      immediate: true,
      handler() {
        this.markers.push({position: this.markersProps});
      }
    },
    title: {
      immediate: true,
    }
  },
  methods: {
    sendEmit(data) {
      this.$emit("getAddressAndPoi", data);
    },

    setPlace(place) {
      console.log(place)
      this.currentPlace = place;
      console.log(this.currentPlace);
      const marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      };
      this.markers.push({position: marker});
      this.places.push(this.currentPlace);
      this.center = marker;

      if (this.currentPlace.formatted_address !== null) {
        this.address = this.currentPlace.formatted_address
      }

      let objSend = {
        address: this.address,
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng(),
      };
      console.log(objSend)
      this.sendEmit(objSend);
      this.currentPlace = null;
    },

    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({position: marker});
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
  },
  props: {
    addressProps: String,
    markersProps: Object,
    centerProps: Object,
    title: String
  },
};
</script>

<style>
input.pac-target-input {
  width: 100%;
}
</style>
